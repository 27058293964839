<template>
    <div class="title-green" :style="styles">
        <div class="text-background">
            <img ondragstart="return false" :src="this.textBackground" alt="">
        </div>
        <div class="title-text">{{ title }}</div>
    </div>
</template>

<script>
    export default {
        name: "TitleGreen",
        props: {
            title: String,
            color: {
                type: String,
                default: '#FFFFFF'
            },
            textBackground: String
        },
        data() {
            return {
                styles: {
                    color: this.color,
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .title-text{
        position: relative;
        z-index: 2;
    }
    .title-green {
        display: inline-block;
        font-size: 48px;
        font-weight: 500;
        line-height: 67px;
        position: relative;
        padding-bottom: 20px;
        padding-top: 32px;

        .text-background {
            position: absolute;
            bottom: 40%;
            z-index: 0;
            height: 66px;
            text-align: center;
            width: 500px;
            left: 50%;
            margin-left: -250px;
            img{
                height: 100%;
                width: auto;
            }
        }

        &:after {

            content: ' ';
            display: block;
            width: 80px;
            height: 6px;
            background-color: #2FC49D;
            line-height: 0;
            bottom: 0;
            left: 50%;
            margin-left: -40px;
            position: absolute;


        }
    }


</style>