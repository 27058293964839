<template>
    <div class="spc-container">
        <div class="spc">

            <div class="spc-top">
                <div class="title">
                    <title-green title="课程教学特色"></title-green>
                </div>

                <div class="overflow-hidden">
                    <div class="spc-card pull-left">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc1.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            课程研发 <br>
                            <span>原创独立</span>知识产权
                        </div>
                    </div>

                    <div class="spc-card pull-right">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc2.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            <span>功能强大完善</span>的 <br>
                            学习管理支持平台
                        </div>
                    </div>
                </div>


                <div class="overflow-hidden">
                    <div class="spc-card pull-left">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc3.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            课程研发 <br>
                            <span>原创独立</span>知识产权
                        </div>
                    </div>

                    <div class="spc-card pull-right">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc4.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            <span>功能强大完善</span>的 <br>
                            学习管理支持平台
                        </div>
                    </div>
                </div>


            </div>
            <div class="spc-middle">
                <img ondragstart="return false" src="@/assets/images/fashion/spc-m.png" alt="">
            </div>


            <div class="spc-bottom">

                <div class="title">
                    <title-green title="终身赋能模式"></title-green>
                </div>

                <div class="overflow-hidden">
                    <div class="spc-card pull-left">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc5.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            <span>AI</span> 形象穿搭<br>
                            检测技术支持
                        </div>
                    </div>

                    <div class="spc-card pull-right">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc6.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            YOCHUR<br>
                            时尚思享会<span>校友圈层</span>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>

                <div class="overflow-hidden">
                    <div class="spc-card pull-left">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc7.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            <span>就业赋能</span> <br>
                            生态流量扶持计划
                        </div>
                    </div>

                    <div class="spc-card pull-right">
                        <div class="img">
                            <img src="@/assets/images/fashion/spc8.png" alt="">
                        </div>

                        <div class="spc-card-desc">
                            <span>私域客户</span><br>
                            云端衣橱管理搭配工具
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import TitleGreen from "./TitleGreen";
    export default {
        name: "LSpc",
        components: {TitleGreen}
    }
</script>

<style lang="less" scoped>
    .spc-container {
        background-color: rgba(16, 16, 16, 1);
    }

    .spc {

        padding-top: 205px;
        padding-bottom: 199px;
        width: 1181px;
        margin: 0 auto;

        .spc-top, .spc-bottom {
            position: relative;
            overflow: hidden;
        }
    }

    .spc-middle {
        width: 1181px;
        margin-bottom: 125px;
        max-width: 100%;

        img {
            max-width: 100%;
        }
    }

    .spc .title {
        width: 300px;
        position: absolute;
        top: 65px;
        left: 50%;
        margin-left: -150px;
    }

    .spc-card {
        position: relative;
        padding-left: 120px;
        margin-bottom: 80px;
        display: inline-block;

        .img {
            position: absolute;
            left: 0;
            height: 100%;
            padding: 5px 0;
            line-height: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .spc-card-desc {
            text-align: left;
            font-size: 24px;
            font-weight: normal;
            color: #f9f9f9;
            line-height: 50px;

            span {
                font-weight: bold;
            }
        }
    }
</style>